export const ALERT = {
    success: {
        type: 'success',
        message: 'Upload finished successfully.'
    },
    error: {
        type: 'error',
        message: 'Error occured when uploading reports'
    }
}

export const ANNOTATION_COLUMN = 1

export const COLUMNS = [
    'Date',
    // This column is to show the yellow line in the chart when an url has content added, if null it won't be shown
    {type: 'string', role: 'annotation'},
    'Click',
    { type:'boolean', role:'certainty' },
    'Impression',
    { type:'boolean', role:'certainty' },
    'Session',
    { type:'boolean', role:'certainty' },
    'Transaction',
    { type:'boolean', role:'certainty' },
    'Revenue',
    { type:'boolean', role:'certainty' },
    // This column will contain certanty indicator.
    // If date from row data is in the future, certanty will be false.
    // This will affect the line style. Future (dashed), present or past (normal)
]
