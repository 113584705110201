import React, { useState, useEffect } from "react";
import CustomChart from "../components/CustomChart"
import TagFilterComboBox from "../components/TagFilterComboBox"
import UrlFilterComboBox from "../components/UrlFilterComboBox"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styles from "../styles/Dashboard.module.css";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card'
import AddIcon from '@mui/icons-material/Add';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DateRangePick from "../components/DateRangePick"
import moment from "moment";
import {ExportToCsv} from "export-to-csv";
import Cookies from "universal-cookie";
import UploadCSVBox from "../components/uploadCsvBox/UploadCSVBox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {COLUMNS, ALERT, ANNOTATION_COLUMN} from "../constants";
import { useFeatureFlag } from "configcat-react";

const Dashboard = () => {
    const cookies = new Cookies();
    const DEFAULT_FILTER = 'gn_sum';
    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const API_KEY = localStorage.getItem('key');
    const VIEW_ID = cookies.get('viewId') || 201215609;
    const FILTER_TYPES = { URL: 'url', TAG: 'tag' }

    const [formats, setFormats] = useState(['session', 'impression']);
    const [openAlert, setOpenAlert] = useState(false)
    const [alertType, setAlertType] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [fileInputDisabled, setFileInputDisabled] = useState(false)
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState([new Date(new Date().setDate(new Date().getDate() - 90)), new Date()]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [chartData, setChartData] = useState(null);

    const {value: FFShowUploadCsv} = useFeatureFlag('FF_SHOW_UPLOAD_CSV', true)

    const getFiltersValue = () => selectedTag || selectedUrl || DEFAULT_FILTER;
    const getFilterType = () => selectedUrl ? FILTER_TYPES.URL : FILTER_TYPES.TAG
    const handleKpis = (_event, newFormats) => setFormats(newFormats);
    const handleDates = (newDates) => {
        if(moment(newDates[0], "MM/DD/YYYY", true).isValid() && moment(newDates[1], "MM/DD/YYYY", true).isValid()){
            setDates(newDates);
        }
    };

    const loadChartData = () => {
        const chartConfiguration = [COLUMNS];
        const filterParam = getFiltersValue();
        const filterType = getFilterType();

        const urlBlueprint = `${API_URL}getUrlPerformance?${filterType}=${filterParam}&startDate={startDate}&endDate={endDate}&view_id=${VIEW_ID}`
        const url = urlBlueprint
            .replace('{startDate}', dates[0].toISOString().split('T')[0])
            .replace('{endDate}', dates[1].toISOString().split('T')[0]);

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + API_KEY
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data && data.length) {
                data.forEach(({ date, click, impression, session, transaction, revenue, content_date }) => {
                    const certainty = new Date() > new Date(date);
                    chartConfiguration.push([
                        new Date(date),
                        content_date,
                        click,
                        certainty,
                        impression,
                        certainty,
                        session,
                        certainty,
                        transaction,
                        certainty,
                        revenue,
                        certainty,
                    ].map((item, key) => {
                        // if we dont make an exception for the annotation we will have every X value with a annotation text = 0
                        // to prevent to appear that 0 we return the existing value or null (Handled in the API)
                        if (key === ANNOTATION_COLUMN) {
                            return item
                        }

                        return !item ? 0 : item;
                    }))
                })
            } 

            setChartData(chartConfiguration);
        });
    }

    useEffect(() => {
        loadChartData()
    }, [selectedTag, selectedUrl, dates])

    const downloadCSV = () => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true
        };
        const csvExporter = new ExportToCsv(options);
        const headers = [...["date", "url"], ...formats];
        const csv = [headers];
        const filterParam = getFiltersValue();
        const filterType = getFilterType();
        const urlBlueprint = `${API_URL}getUrlPerformance/?${filterType}=${filterParam}&startDate={startDate}&endDate={endDate}&view_id=${VIEW_ID}`
        const url = urlBlueprint
            .replace('{startDate}', dates[0].toISOString().split('T')[0])
            .replace('{endDate}', dates[1].toISOString().split('T')[0])

        fetch(url,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + API_KEY
            }})
            .then(data => data.json())
            .then(data => {
                data.forEach(element => {
                    let row = [];
                    console.log(element);
                    headers.forEach(header => {
                        row.push(element[header])
                    });
                    csv.push(row);
                });
                csvExporter.generateCsv(csv);
            });
    };

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            borderRadius: 4,
            '&:not(:first-of-type)': {
                borderRadius: 4,
            },
            '&:first-of-type': {
                borderRadius: 4,
            },
        },
    }));

    const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
        '&.Mui-selected':
            {
                backgroundColor: alpha("#FBBF48", 0.9),
                '&:hover':{
                    backgroundColor: alpha("#FBBF48", 0.9)
                }
            },
        '&.MuiToggleButton-root':{
            '&:hover':{
                backgroundColor: alpha("#FBBF48", 0.2)
            }
        },
    }));

    const uploadFileHandler = (event) => {
        setLoading(true)
        setFileInputDisabled(true)

        const path = 'reports/';
        const data = new FormData()
        data.append('file', event.target.files[0])
        data.append('view_id', VIEW_ID)

        fetch(`${API_URL}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + API_KEY
            },
            body: data
        })
        .then(response => {
            if (!response.ok) {
                setAlertType(ALERT.error.type)
                setAlertMessage(ALERT.error.message)
                return;
            };

            setAlertType(ALERT.success.type)
            setAlertMessage(ALERT.success.message)
        })
        .finally(() => {
            setLoading(false)
            setOpenAlert(true)
            setFileInputDisabled(false)
        })
    }

    const handleAlertClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);

        // Retrieves chart data after alert is successful & gone
        if (alertType === ALERT.success.type) {
            loadChartData();
        }
    };

    const closeAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleAlertClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Box>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                <Grid container spacing={3}>
                    {/*Filters*/}
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "light"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Filter by Tag
                        </Typography>
                        <Card sx={{ p: 2, pt:0, pl:0, pr:3, display: 'flex', flexDirection: 'column', border: "none", boxShadow: "none" }}>
                            <TagFilterComboBox value={selectedTag} setValue={setSelectedTag} />
                        </Card>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "light"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Filter by Url
                        </Typography>
                        <Card sx={{ p: 2, pt:0, pl:0, pr:3, display: 'flex', flexDirection: 'column', border: "none", boxShadow: "none" }}>
                            <UrlFilterComboBox value={selectedUrl} setValue={setSelectedUrl} />
                        </Card>
                    </Grid>
                    {/*Date Picker*/}
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "light"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Date Range
                        </Typography>
                        <Card sx={{ p: 2, pt:1, pl:0, pr:3, display: 'flex', flexDirection: 'column', border: "none", boxShadow: "none" }}>
                            <DateRangePick dates={dates} handleDates={handleDates}/>
                        </Card>
                    </Grid>
                    {/* Chart */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                p: 2, pl:0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 360,
                                border: "none",
                                boxShadow: "none"
                            }}
                        >
                            <CustomChart chartData={chartData} />
                        </Paper>

                    </Grid>
                    {/* KPI Filters and Tag Filters, Download csv button */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "light"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            KPI Filters
                        </Typography>
                        <Card sx={{ p: 2, pb:0,pl:0, display: 'flex', flexDirection: 'row' ,border: "none", boxShadow: "none"}}>

                            <StyledToggleButtonGroup
                                value={formats}
                                onChange={handleKpis}
                                aria-label="text formatting"
                            >
                                <StyledToggleButton value="impression" aria-label="impression" className={styles.kpiButton} sx={{m:1, ml:0, fontWeight: "500"}} variant="outlined" >
                                    <AddIcon />
                                    IMPRESSIONS
                                </StyledToggleButton>
                                <StyledToggleButton value="click" aria-label="click" className={styles.kpiButton} sx={{m:1, fontWeight: "500"}} variant="outlined" ><AddIcon/>CLICKS</StyledToggleButton>
                                <StyledToggleButton value="session" aria-label="session" className={styles.kpiButton} sx={{m:1, fontWeight: "500"}} variant="outlined" ><AddIcon/>SESSIONS</StyledToggleButton>
                            </StyledToggleButtonGroup>
                        </Card>
                        <Card sx={{ p: 2, pt:0,pl:0, display: 'flex', flexDirection: 'row', border: "none", boxShadow: "none" }}>
                            <StyledToggleButtonGroup
                                value={formats}
                                onChange={handleKpis}
                                aria-label="selected kpis"
                            >
                                <StyledToggleButton value="transaction" aria-label="transaction" className={styles.kpiButton} sx={{m:1,ml:0, fontWeight: "500"}} variant="outlined" ><AddIcon />TRANSACTIONS</StyledToggleButton>
                                <StyledToggleButton value="coversion_rate" aria-label="coversion_rate" className={styles.kpiButton} sx={{m:1, fontWeight: "500"}} variant="outlined" ><AddIcon />CONVERSION RATE</StyledToggleButton>
                                <StyledToggleButton value="revenue" aria-label="revenue" className={styles.kpiButton} sx={{m:1, fontWeight: "500"}} variant="outlined" ><AddIcon />REVENUE</StyledToggleButton>
                            </StyledToggleButtonGroup>
                        </Card>

                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "light"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Generate Report
                        </Typography>
                        <Card sx={{ p: 2,m:1, display: 'flex', pb:6, mr:6,
                            flexDirection: 'column', cursor:"pointer",
                            boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.12)",
                            justifyItems:"center", justifyContent:"center" }} onClick={downloadCSV}>
                            <FileDownloadOutlinedIcon fontSize="large" sx={{ width : "100%", mb:2, mt:2 }} style={{ fill: "#FBBF48"}} />
                            <Typography
                                component="p"
                                variant="p"
                                fontWeight = "900"
                                color='black'
                                width="100%"
                                textAlign={'center'}
                                letterSpacing={2}
                            >
                                DOWNLOAD <br/> CSV FILE
                            </Typography>

                        </Card>
                        <Typography
                            component="p"
                            variant="p"
                            fontWeight = "300"
                            width="100%"
                            fontSize={15}
                        >
                            This report will include only your selected filters.
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {FFShowUploadCsv &&
                            <>
                                <Typography
                                    component="p"
                                    variant="p"
                                    fontWeight="light"
                                    marginBottom={1}
                                    noWrap
                                    sx={{flexGrow: 1}}
                                >
                                    Upload Report
                                </Typography>

                                <UploadCSVBox inputDisabled={fileInputDisabled} selectorChange={uploadFileHandler}
                                              loading={loading}/>
                                <Typography
                                    component="p"
                                    variant="p"
                                    fontWeight="300"
                                    width="100%"
                                    fontSize={15}>Formats allowed: .xlsx, .xls and .csv.
                                </Typography>
                            </>
                        }
                        <Snackbar
                            open={openAlert}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            autoHideDuration={3000}
                            onClose={handleAlertClose}
                            action={closeAction}
                        >
                            <Alert severity={alertType}> {alertMessage}</Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Dashboard;
