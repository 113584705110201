import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useEffect, useState} from "react";
import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.metro.de/marktplatz/">
                Metro Marketplatz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function setNewPassword(data){



}

export default function PassReset(props) {
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    const id = props.match.params.id;
    const key = props.match.params.key;
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('success');

    const showSnackBar = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            new_password1: data.get('password'),
            new_password2: data.get('password'),
            uid: id,
            token: key,
        }

        fetch(`${backendUrl}dj-rest-auth/password/reset/confirm/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then(() => {
                setAlertType('success');
                setMessage('Password changed successfully');
                showSnackBar();
            })
            .catch(() => {
                setAlertType('error');
                setMessage('Error occurred.');
                showSnackBar();
            });



    };

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            setLoading(false);
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {errors === true && <h2>Cannot log in with provided credentials</h2>}
            {loading === false && (
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Password Reset
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="off"
                            inputProps={{ minLength: 8 }}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            )}
            <Copyright sx={{ mt: 8, mb: 4 }} />
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert severity={alertType}> {message} </Alert>
            </Snackbar>
        </Container>
    );
}