import * as React from 'react';
import {Chip} from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";

const TagDetail = ({tag, tagDetails, setTagDetails, setTagField, setOpen, setReadOnly, saved, setSaved, view_id }) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const key = localStorage.getItem('key');


    const handleDelete = (item) => {
        fetch(`${backendUrl}tags/?view_id=${view_id}&url=${item}&tag=${+tag}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        }).then()
            .then(data => {
                setSaved(Math.random());
            });
    };

    const addNew = (event) => {
        setTagField(tag);
        setReadOnly(true);
        setOpen(true);
    };

    React.useEffect(() => {
        fetch(`${backendUrl}tags/?view_id=${view_id}&tag=${tag}`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        })
            .then(res => res.json())
            .then(res => {
                let arr = [];
                res.forEach(el => {
                    arr.push(el.url);
                });
                setTagDetails(arr);
            });
    }, [tag, saved]);

    return (
        <div>
            <div>Tag: {tag}</div>
            <List>
            {tagDetails.map(item => {
                return <div><Chip key={item} label={item} sx={{m:1, ml:0}} variant="outlined" onDelete={ () => handleDelete(item) } /> <br/></div> ;
            })}
            </List>
            <Button variant="contained" onClick={addNew} sx={{float: "right", m:1, borderRadius: "16px"}}>Add Url</Button>
        </div>
    );
}


export default TagDetail;