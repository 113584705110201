import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';
import UrlFilterComboBox from "../components/UrlFilterComboBox";
import TextField from '@mui/material/TextField';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Cookies from 'universal-cookie';
import UploadCSV from "../components/UploadCSV";


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cookies = new Cookies();
const view_id = cookies.get('viewId') || 201215609;

export default function Content() {
    const [data, setData] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [dateVal, setDateVal] = useState(new Date());
    const key = localStorage.getItem('key');

    const handleDateChange = (newValue) => {
        setDateVal(newValue);
    };

    const saveContent = () =>{
        const data = {
            url: selectedUrl,
            date: dateVal.toISOString().split('T')[0],
            view_id: view_id,
            status: 1
        };
        fetch(`${backendUrl}contents/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(res => {
                console.log(res);
                setDeleted(res)
            })
            .catch(err => console.log(err));
    };


    const deleteItem = (id) => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            },
            body: JSON.stringify({ status: 0  })
        };
        fetch(`${backendUrl}contents/${id}/?view_id=${view_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setDeleted(data);
            })
    };



    const columns = [
        { field: 'id', headerName: 'ID', width: 95 },
        { field: 'url', headerName: 'Url', width: 250 },
        { field: 'date', headerName: 'Date', width: 130 },
        { field: 'status', headerName: 'Status', width: 130, renderCell: (params) => (
                params.value === 1 ? 'Active' : 'Deactivated'
            ), },
        { field: 'button', headerName: '', width: 130, renderCell: (params) => (
                <DeleteIcon sx={{cursor: 'pointer'}} onClick={() => deleteItem(params.row.id)} />
            ), },

    ];

    React.useEffect(() => {
        fetch(`${backendUrl}contents/?view_id=${view_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        })
            .then(res => res.json())
            .then(data => {
                setData(data);
            });
    }, [deleted]);

    return (
        <Box p={2}>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={8}>

                        <Paper
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 300
                            }}>
                            <Typography
                            component="h6"
                            variant="h6"
                            noWrap
                        >
                            Enter Content details:
                        </Typography>
                        <UrlFilterComboBox value={selectedUrl} setValue={setSelectedUrl} />
                            <Paper
                            sx={{
                                pt: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                border: "none",
                                boxShadow: "none"
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                sx={{
                                    p:4,
                                }}
                                label="Date"
                                inputFormat="dd/MM/yyyy"
                                value={dateVal}
                                maxDate = {new Date()}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                            </Paper>
                            <Paper
                                sx={{
                                pt: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                border: "none",
                                boxShadow: "none"
                            }}>
                                <UploadCSV setDeleted={setDeleted} />
                                <Button sx={{ml: 'auto'}} variant="contained" onClick={saveContent}>Save</Button>
                            </Paper>

                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} lg={8}>
                        <Paper
                            sx={{
                                p: 2, pl:0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 320,
                            }}
                        >
                            <DataGrid
                                rows={data}
                                columns={columns}
                                pageSize={25}
                                rowsPerPageOptions={[10]}
                                sx={{
                                    p: 2,
                                    border: "none",
                                    boxShadow: "none",
                                    height : "100%",
                                    width: "100%"
                                }}

                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}