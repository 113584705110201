import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Signup from './views/auth/Signup';
import Logout from './views/auth/Logout';
import Dashboard from './app/Dashboard';
import Content from './app/Content';
import UserManager from './app/UserManager';
import SignIn from './views/auth/SignIn';
import Tag from './app/Tag';
import Token from './views/auth/Token';
import PassReset from "./views/auth/PassReset";
import { ConfigCatProvider } from 'configcat-react'

const App = () => {
  const { token, setToken } = Token();
  const configCatSdk = process.env.REACT_APP_CONFIGCAT_SDK

  if(!token) {
    return <Router>
        <Navbar>
            <Switch>
                <Route exact path='/user/password-reset/confirm/:id/:key' component={PassReset} />
                <Route exact path='/' component={() => (<SignIn setToken={setToken} />)}  />
                <SignIn setToken={setToken} />
            </Switch>
        </Navbar>
    </Router>
  }


  return (
  <ConfigCatProvider sdkKey={configCatSdk}>
      <div className='App'>
          <Router>
              <Navbar>
                  <Switch>
                      <Route exact path='/' component={Dashboard}/>
                      <Route exact path='/signup' component={Signup}/>

                      <Route exact path='/logout' component={Logout}/>
                      <Route exact path='/content' component={Content}/>
                      <Route exact path='/tag' component={Tag}/>
                      <Route exact path='/dashboard' component={Dashboard}/>
                      <Route exact path='/userManager' component={UserManager}/>
                  </Switch>
              </Navbar>
          </Router>
      </div>
  </ConfigCatProvider>

  );
};

export default App;