import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Cookies from "universal-cookie";

const TagFilterComboBox = ({ value, setValue }) => {
  const cookies = new Cookies();
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const API_KEY = localStorage.getItem('key');
  const VIEW_ID = cookies.get('viewId') || 201215609;

  const [tagList, setTagList] = useState([])

  const handleTags = (seletctedTag) => {
    setValue(seletctedTag);
  };

  const loadList = () => {
    fetch(`${API_URL}tagList?view_id=${VIEW_ID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + API_KEY
      }
    })
      .then(res => res.json())
      .then(res => {
        setTagList(res.tag);
      });
  }

  useEffect(() => {
    loadList();
  }, []);

  return (
      <Autocomplete
          disablePortal
          value={value}
          onChange={(_event, selectedTag) => handleTags(selectedTag)}
          id="filter-tags"
          options={tagList}
          sx={{width: '100%', mt: 1}}
          renderInput={(params) => <TextField {...params} label="Tag"/>}
      />
  );
}


export default TagFilterComboBox;