import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.metro.de/marktplatz/">
                Metro Marketplatz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;


function getUserDetails(key){
    fetch(`${backendUrl}dj-rest-auth/user/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + key
        },
    })
        .then(res => res.json())
        .then(data => {
            if (data.email) {
                localStorage.setItem('email', data.email);
                localStorage.setItem('is_staff', data.is_staff);
                window.location.replace('/dashboard');
            } else {
                localStorage.clear();
            }
        });
}

export default function SignIn({ setToken }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [fpemailField, setFPemailField] = useState('');
    const [response, setResponse] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user = {
            email: data.get('email'),
            password: data.get('password')
        };



        fetch(`${backendUrl}dj-rest-auth/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(data => {
                if (data.key) {
                    localStorage.clear();
                    localStorage.setItem('key', data.key);
                    setToken(data.key);
                    getUserDetails(data.key);
                } else {
                    setEmail('');
                    setPassword('');
                    localStorage.clear();
                    setErrors(true);
                }
            });
    };

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            setLoading(false);
        }
    }, []);

    const addNew = (event) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleResetPassword = (event) => {
        event.preventDefault();
        const user = {
            email: fpemailField
        };

        fetch(`${backendUrl}dj-rest-auth/password/reset/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(data => {
                if (data.detail) {
                    setFPemailField('');
                    setResponse(data.detail);
                } else {
                    setResponse('Error occurred. Please try again.');
                }
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {errors === true && <h2>Cannot log in with provided credentials</h2>}
            {loading === false && (
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            type='email'
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" onClick={addNew} variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                        <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>Reset Your Password</DialogTitle>
                                    {response !== false && <DialogContent>{response}</DialogContent>}
                                    {response === false && (
                                    <DialogContent>
                                        <DialogContentText>
                                            Please type your e-mail to reset your password.
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="email"
                                            label="email"
                                            type="email"
                                            fullWidth
                                            variant="standard"
                                            required={true}
                                            value={fpemailField}
                                            onChange={(event) => setFPemailField(event.target.value)}
                                        />
                                    </DialogContent> )}
                                    {response === false && (
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={handleResetPassword}>Confirm</Button>
                                    </DialogActions>
                                        )}
                                </Dialog>
                    </Box>
                </Box>
            )}
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}

SignIn.propTypes = {
    setToken: PropTypes.func.isRequired
}