import React, { useState, useEffect, Fragment } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ViewIDPicker from '../../components/ViewIDPicker';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import styles from '../../styles/Navbar.module.css'
import { mainListItems } from './listItems';

const drawerWidth = 240;
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const key = localStorage.getItem('key');

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(0),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(0),
                },
            }),
        },
    }),
);

const handleLogout = e => {
    e.preventDefault();

    fetch(`${backendUrl}dj-rest-auth/logout/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + key
        }
    })
        .then(res => res.json())
        .then(data => {
            console.log(data);
            localStorage.clear();
            window.location.replace('/');
        });
};
const mdTheme = createTheme({
    typography: {
        color: '#FBBF48',
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FBBF48',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
    },

});

const Navbar = (props) => {
    const [isAuth, setIsAuth] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            setIsAuth(true);
        }
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            backgroundColor: "#FBBF48"
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link href="/dashboard">
                            <img src="metro_logo.png" alt="logo" className={styles.logo}/>
                        </Link>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="black"
                            fontWeight = "regular"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Metro Markets Performance Tool
                        </Typography>

                        {isAuth === true ? (

                            <Fragment>
                                {' '}
                                <ViewIDPicker />
                                <Button onClick={handleLogout}
                                sx={{
                                            borderRadius: '5px',
                                            bgcolor: '#000',
                                          ':hover': {
                                              bgcolor: '#000', // theme.palette.primary.main
                                          },}}
                                >
                                    Logout
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {' '}
                                <Link  color='#000' href='/'
                                      sx={{ textDecoration: 'none',
                                            borderRadius: '5px',
                                            bgcolor: '#000',
                                          ':hover': {
                                              bgcolor: '#000', // theme.palette.primary.main
                                          },}}>
                                    <Button >
                                    Sign In
                                    </Button>
                                </Link>
                            </Fragment>
                        )}
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}

                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List>{mainListItems}</List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    {props.children}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Navbar;