import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-google-charts";
import { INITI_SERIES } from "../constants";

const CustomChart = ({ chartData }) => {
    const [series, setSeries] = useState(INITI_SERIES);
    const [copyChartData, setCopyChartData] = useState({});

    const options = {
        theme: 'material',
        interpolateNulls: true,
        chart: {
            title: "Chart Data"
        },

        crosshair: {orientation: 'vertical', trigger: 'focus'},
        legend: {
            position: 'right'
        },
        hAxis: {
            format: 'd/M/yy',
            gridlines: {
                color: 'transparent'
            }
        },

        chartArea: {left:50, right:125, top:20, bottom:20 },
        annotations: {
            stem: {
                color: '#FBBF48',
            },
            style: 'line'
        },
        series: series
    }
    

    useEffect(() => {
        setCopyChartData(chartData);
        setSeries(INITI_SERIES);
    }, [chartData]);

    const removeSelectedColumn = (column) => {
        const updatedData = copyChartData.map((dataList, index) => {
            if (!!index) {
                return dataList.map((item, index) => {
                    if (index === column) {
                        return 0
                    }

                    return item
                })
            }

            return dataList
        })

        setCopyChartData(updatedData)
    }

    const restoreSelectedColumn = (column) => {
        const data = [...[], ...copyChartData]
        chartData.forEach((datalist, index) => {
            if (!!index) {
                data[index][column] = datalist[column]
            }
        })

        setCopyChartData(data);
    }

    const handleSelection = (chartWrapper) => {
        // 1. Get chart data
        // 2. Get selected column index (based on first chartData element list order)
        // 3. Check matching column
        // 4. If visible, I update line style and put all data from entire colum as 0
        // 5. If not visible, I update line style and put back original data for entire colum
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        const [selectedItem] = selection;
        const { row, column } = selectedItem;

        if (!selection.length || row !== null) {
            return;
        }

        const seriesList = Object.values(series).map((serie) => {
            if (serie.id === column) {
                if (serie.isVisible) {
                    removeSelectedColumn(column);
                    return {
                        ...serie, 
                        lineWidth: 0, // fake hide
                        tooltip: false, // disable tooltips so you can't get tooltip when you hover the area where one point of "inivisibe" line is
                        isVisible: false
                    };
                }
                
                restoreSelectedColumn(column);
                return {
                    ...serie,
                    lineWidth: 2,
                    tooltip: true,
                    isVisible: true,
                };
            }

            return serie;
        })

        const newSeries = seriesList.reduce((accumulator, currect, index) => {
            accumulator[index] = currect;
            return accumulator;
        }, {}); 

        setSeries(newSeries);
    }

    return (
        <React.Fragment>
            {copyChartData && copyChartData.length > 1 &&
                <Chart
                    height={'100%'}
                    width={'100%'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={copyChartData}
                    options={options}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                handleSelection(chartWrapper);
                            }
                        }
                    ]}
                    // For tests
                    rootProps={{ "data-testid": "1" }}
                />
            }

            {!copyChartData || copyChartData.length <= 1 && <p>No data found from your search input</p>}
        </React.Fragment>
    );
};

export default CustomChart;