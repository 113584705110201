import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import {Chip} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import UrlFilterComboBox from "../components/UrlFilterComboBox";
import TagDetail from "../components/TagDetail";
import Cookies from "universal-cookie";
import UploadCSV from "../components/UploadCSV";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cookies = new Cookies();
const view_id = cookies.get('viewId') || 201215609;

export default function Tag() {

    const [data, setData] = React.useState({tag: []});
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [tag, setTag] = React.useState('');
    const [tagField, setTagField] = React.useState('');
    const [tagDetails, setTagDetails] = React.useState([]);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [saved, setSaved] = React.useState(null);
    const [readOnly, setReadOnly] = React.useState(false);
    const key = localStorage.getItem('key');


    const handleClose = () => {
        setReadOnly(false);
        setTagField('');
        setOpen(false);
    };
    const handleDelete = (item) => {
        fetch(`${backendUrl}tags/?view_id=${view_id}&tag=${item}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        }).then()
            .then(data => {
                setSaved(Math.random());
            });
    };
    const handleClick = (item) => {
        setTag(item);
        setShow(true);
    };

    const addNew = (event) => {
        setOpen(true);
    };

    const handleSave = (event) => {
        fetch(`${backendUrl}tags/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            },
            body: JSON.stringify({
                tags: tagField,
                url: selectedUrl,
                view_id: view_id
            })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setOpen(false);
                setTagField('');
                setReadOnly(false);
                setSelectedUrl(null)
                setSaved(data);
            });
    };

    React.useEffect(() => {
        fetch(`${backendUrl}tagList?view_id=${view_id}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }})
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setData(data);
            });
    }, [saved]);

    return (
        <Box p={2}>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={8}>

                        <Typography
                            component="h6"
                            variant="h6"
                            noWrap
                            maxWidth={250}
                        >
                            Tag List:
                        </Typography>
                        <Paper
                            sx={{
                                p: 2,
                                mr:4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                        <Paper
                            sx={{
                                p: 2,
                                mb:2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}>

                            <Grid item justifyContent="center">
                                {data["tag"].map(item => {
                                    return <Chip key={item} label={item} sx={{m:1, ml:0}} variant="outlined" onClick={ () => handleClick(item) } onDelete={ () => handleDelete(item) } />;
                                })}

                            </Grid>
                        </Paper>
                        <Paper
                                sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                border: "none",
                                boxShadow: "none"
                            }}>
                                <UploadCSV setDeleted={setSaved} />
                                <Button variant="contained" onClick={addNew} sx={{ml: 'auto'}}>Add Tag</Button>
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>Add a New Tag</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Please fill out the form in order to create new tag.
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="Tag"
                                            label="Tag"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            required={true}
                                            value={tagField}
                                            onChange={(event) => setTagField(event.target.value)}
                                            inputProps={
                                                { readOnly: Boolean(readOnly), }
                                            }
                                        />
                                        <UrlFilterComboBox value={selectedUrl} setValue={setSelectedUrl} />

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={handleSave}>SAVE</Button>
                                    </DialogActions>
                                </Dialog>
                            </Paper>
                        </Paper>
                    </Grid>
                    {show && <Grid item xs={12} lg={8}>
                        <Paper
                            sx={{
                                p: 2,
                                mr:4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <TagDetail tag={tag} tagDetails={tagDetails} setTagDetails={setTagDetails} setTagField={setTagField} setOpen={setOpen} setReadOnly={setReadOnly} saved={saved} setSaved={setSaved} view_id={view_id}/>
                        </Paper>
                    </Grid>}

                </Grid>
            </Container>
        </Box>
    );
}