import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Cookies from "universal-cookie";

const UrlFilterComboBox = ({ value, setValue }) => {
  const cookies = new Cookies();
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const API_KEY = localStorage.getItem('key');
  const VIEW_ID = cookies.get('viewId') || 201215609;

  const [urlList, setUrlList] = useState(['gn_sum'])

  const handleUrls = (selectedUrl) => {
    setValue(selectedUrl);
  };

  const loadList = () => {
    fetch(`${API_URL}urls/?view_id=${VIEW_ID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + API_KEY
      }
    })
      .then(res => res.json())
      .then(res => {
        setUrlList(res.url);
      });
  }

  useEffect(() => {
    loadList();
  }, []);

  return (
      <Autocomplete
          disablePortal
          value={value}
          onChange={(_event, selectedUrl) => handleUrls(selectedUrl)}
          id="filter-urls"
          options={urlList}
          sx={{width: '100%', mt: 1}}
          renderInput={(params) => <TextField {...params} label="Url"/>}
      />
  );
}


export default UrlFilterComboBox;