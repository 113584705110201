import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Cookies from 'universal-cookie';
import {styled} from "@mui/material/styles";


export default function ViewIDPicker() {
  const BootstrapSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

  const cookies = new Cookies();

  const initState = cookies.get('viewId') || 201215609;
  const [viewId, setViewId] = React.useState(initState);

  const handleChange = (event) => {
    setViewId(event.target.value);
    cookies.set('viewId', event.target.value, { path: '/' });
    window.location.reload();
  };

  return (
    <Box>
      <FormControl fullWidth>
        <BootstrapSelect
          labelId="country-select-label"
          id="country-simple-select"
          value={viewId}
          label="viewId"
          onChange={handleChange}
          color={"secondary"}
        >
          <MenuItem value={201215609}>Germany</MenuItem>
          <MenuItem value={237133531}>Spain</MenuItem>
        </BootstrapSelect>
      </FormControl>
    </Box>
  );
}