import React from "react";
import "./UploadCSVBox.css"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";

const UploadCSVBox = ({
    selectorChange,
    inputDisabled,
    loading
}) => {
    const dynamicClass = inputDisabled ? 'drop-container drop-container--disabled' : 'drop-container';
    return (
        <label htmlFor="reports" className={dynamicClass}>
            <FileUploadOutlinedIcon fontSize="large" sx={{ width : "100%", mb:2, mt:2 }} style={{ fill: "#FBBF48"}} />
            <Typography
                component="p"
                variant="p"
                fontWeight = "900"
                color='black'
                width="100%"
                textAlign={'center'}
                letterSpacing={2}
            >
                UPLOAD <br/> CSV FILE
            </Typography>
            <input type="file" id="reports" accept=".xlsx, .xls, .csv" hidden required disabled={inputDisabled} onChange={selectorChange} />
            {loading && <CircularProgress/>}
        </label>
    )
}

export default UploadCSVBox;