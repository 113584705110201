import * as React from 'react';
import Button from '@mui/material/Button';
import input from '@mui/material/Input';
import Cookies from "universal-cookie";
import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const UploadCSV = ({setDeleted}) =>{
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const key = localStorage.getItem('key');
    const cookies = new Cookies();
    const view_id = cookies.get('viewId') || 201215609;
    const [open, setOpen] = React.useState(false);

    const showSnackBar = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const changeHandler = (event) => {
        console.log(event.target.files[0]);
        let path;
        if(window.location.pathname === '/content'){
            path = 'contents_csv/'
        }
        else if(window.location.pathname === '/tag'){
            path = 'tags_csv/'
        }
        var data = new FormData()
        data.append('file', event.target.files[0])
        data.append('view_id', view_id)

        fetch(`${backendUrl}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + key
            },
            body: data
        })
            .then(response => {
                if (!response.ok) throw new Error(response.status);
                showSnackBar();
                setDeleted(Math.floor(10000 + Math.random() * 90000));
            })
    };

    return (<div>
            <Button
                variant="contained"
                component="label"
            >
                Upload File
                <input
                    type="file"
                    hidden
                    accept=".xlsx, .xls, .csv"
                    onChange={changeHandler}
                />
            </Button>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={3000}
                onClose={handleClose}
                action={action}>
                <Alert severity='success'> Upload finished successfully.</Alert>
            </Snackbar>
        </div>
    )
}

export default UploadCSV;