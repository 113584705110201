import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const key = localStorage.getItem('key');

  useEffect(() => {
    if (localStorage.getItem('token') == null) {
      window.location.replace('/login');
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogout = e => {
    e.preventDefault();

    fetch(`${backendUrl}dj-rest-auth/logout/`, {
      method: 'POST',
      headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        localStorage.clear();
        window.location.replace('/');
      });
  };

  return (
    <div>
      {loading === false && (
        <Fragment>
          <h1>Are you sure you want to logout?</h1>
          <div>
            <Button variant="contained" onClick={handleLogout} value='Logout'>Logout</Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Logout;