export const INITI_SERIES = {
    0: {
        id: 2,
        hidden: false,
        color: "blue",
        altColor: "transparent",
        lineWidth: 2,
        isVisible: true
    },
    1: {
        id: 4,
        hidden: false,
        color: "red",
        altColor: "transparent",
        lineWidth: 2,
        isVisible: true

    },
    2: {
        id: 6,
        hidden: false,
        color: "orange",
        altColor: "transparent",
        lineWidth: 2,
        isVisible: true
    },
    3: {
        id: 8,
        hidden: false,
        color: "purple",
        altColor: "transparent",
        lineWidth: 2,
        isVisible: true
    },
    4: {
        id: 10,
        hidden: true,
        color: "green",
        altColor: "transparent",
        lineWidth: 2,
        isVisible: true,
        // targetAxisIndex: 1
    },
}