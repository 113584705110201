import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DateRangePicker } from "@mui/x-date-pickers-pro"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import Box from "@mui/material/Box";

const DateRangePick = ({dates, handleDates}) => {
  return (
       <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                startText="Start date"
                                endText="End date"
                                inputFormat="dd/MM/yyyy"
                                value={dates}
                                maxDate = {new Date()}
                                onChange={(newDates) => handleDates(newDates)}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
  );
};

export default DateRangePick;