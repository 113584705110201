import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function UserManager() {
    const [data, setData] = React.useState([]);
    const [deleted, setDeleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [emailField, setEmailField] = React.useState('');
    const [passField, setPassField] = React.useState('');
    const [isAdmin, setIsAdmin] = React.useState(false);
    const key = localStorage.getItem('key');



    const deleteUser = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        };
        fetch(`${backendUrl}api/v1/users/userDelete?id=${id}`, requestOptions)
            .then(res => res)
            .then(data => {
                setDeleted(data)
            })
    };



    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'email', headerName: 'E-Mail', width: 250 },
        { field: 'is_staff', headerName: 'Role', width: 130, renderCell: (params) => (
                params.value === true ? 'Admin' : 'Viewer'
            ), },
        { field: 'button', headerName: '', width: 130, renderCell: (params) => (
                <DeleteIcon sx={{cursor: 'pointer'}} onClick={() => deleteUser(params.row.id)} />
            ), },

    ];

    const handleSave = () => {
        const request = {
            email: emailField,
            password: passField,
            username: emailField,
            is_staff: isAdmin,
        };
        fetch(`${backendUrl}api/v1/users/userCreate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            },
            body: JSON.stringify(request)
        }).then(res => res.json())
            .then(res => {
                console.log(res);
                setDeleted(res);
                setOpen(false);
            })
            .catch(err => console.log(err));
    };


    const handleClose = () => {
        setOpen(false);
    };

    const addNew = (event) => {
        setOpen(true);
    };

    React.useEffect(() => {
        fetch(`${backendUrl}api/v1/users/userList`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + key
            }
        })
            .then(res => res.json())
            .then(data => {
                setData(data);
            });
    }, [deleted]);

    return (
        <Box p={2}>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                <Grid container spacing={3} justifyContent="center">

                    <Grid item xs={12} md={12} lg={6}>

                        <Paper
                            sx={{
                                p: 2, pl:0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 680,
                            }}
                        >
                            <Button variant="contained" onClick={addNew} sx={{float: "right", m:1, borderRadius: "16px", width: "25%"}}>Add User</Button>
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>Add a New User</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Please fill out the form in order to create new user.
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="email"
                                            label="email"
                                            type="email"
                                            fullWidth
                                            variant="standard"
                                            required={true}
                                            onChange={(event) => setEmailField(event.target.value)}
                                        />
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="password"
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            variant="standard"
                                            required={true}
                                            onChange={(event) => setPassField(event.target.value)}
                                        />
                                        Admin Rights:
                                        <Checkbox
                                          checked={isAdmin}
                                          onChange={(event) => setIsAdmin(event.target.checked)}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          label="Label"
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={handleSave}>SAVE</Button>
                                    </DialogActions>
                                </Dialog>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                pageSize={8}
                                rowsPerPageOptions={[10]}
                                sx={{
                                    p: 2,
                                    border: "none",
                                    boxShadow: "none",
                                    height : "100%",
                                    width: "100%"
                                }}

                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}